.App {
	text-align: center;
}

.App-header {
	background-color: darkslategray;
	min-height: 20vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-main {
	background-color: whitesmoke;
	min-height: 80vh;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.search {
	padding: 20px;
}

.list {
	height: 100%;
	text-align: left;
	padding: 0px 10px 20px;
}

.centered {
	display: flex;
	align-items: center;
	flex: 1 0 auto;
}

.centered p {
	font-size: 14pt;
}

.list .count {
	font-weight: bold;
	padding: 0 10px 0 0;
}

img.thumbnail {
	max-width: 50px;
	max-height: 40px;
	margin-right: 10px;
}
